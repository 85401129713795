import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Loader from './components/molecules/Suspense/Loader';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CacheBuster from 'react-cache-buster';
import packageInfo  from '../package.json';


const Home = lazy(() => import('./Pages/Home'));
const Login = lazy(() => import('./Pages/Auth/Login/Index'));
const Logout = lazy(() => import('./Pages/Logout'));
const Register = lazy(() => import('./Pages/Auth/Register/Index'));
const ForgetPassword = lazy(() => import('./Pages/Auth/ForgetPassword'));
const OTPFormPage = lazy(() => import('./Pages/Auth/OTPFormPage'));
const FormForgetPasswordPage = lazy(() => import('./Pages/Auth/FormForgetPasswordPage'));
const CongratsPage = lazy(() => import('./Pages/CongratsPage'));
const Minat = lazy(() => import('./Pages/Minat'));
const CreateCommunity = lazy(() => import('./Pages/CreateCommunity'));
const ShareCommunity = lazy(() => import('./Pages/ShareCommunity'));
const Profile = lazy(() => import('./Pages/Profile/Profile'));
const Penarikan = lazy(() => import('./Pages/Profile/Penarikan'));
const Riwayat = lazy(() => import('./Pages/Profile/Riwayat'));
const ProfileUpdate = lazy(() => import('./Pages/Profile/ProfileUpdate'));
const DetailCommunity = lazy(() => import('./Pages/Community/DetailCommunity'));
const EditCommunity = lazy(() => import('./Pages/Community/EditCommunity'));
const CreateJadwalRegular = lazy(() => import('./Pages/Activity/CreateJadwalRegular'));
const CreateJadwalOnce = lazy(() => import('./Pages/Activity/CreateJadwalOnce'));
const EditActivityRegular = lazy(() => import('./Pages/Activity/EditActivityRegular'));
const EditJadwalOnce = lazy(() => import('./Pages/Activity/EditJadwalOnce'));
const AktivitasPage = lazy(() => import('./Pages/AktivitasPage'));
const SuksesBergabungAktivitas = lazy(() => import('./Pages/SuksesBergabungAktivitas'));
const JoinPage = lazy(() => import('./Pages/JoinPage'));

const SearchPage = lazy(() => import('./Pages/SearchPage'));
const SearchFoundPage = lazy(() => import('./Pages/SearchFoundPage'));

const ExplorePage = lazy(() => import('./Pages/ExplorePage'));

const queryClient = new QueryClient();

function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
      currentVersion={packageInfo?.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'}
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Loader />}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Slide}
            />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/daftar" element={<Register />} />
              <Route path="/daftar/berhasil" element={<CongratsPage />} />
              <Route path="/lupa-password" element={<ForgetPassword />} />
              <Route path="/otp/:email" element={<OTPFormPage />} />
              <Route path="/otp/:email/success" element={<FormForgetPasswordPage />} />

              {/* aktivitas pribadi */}
              <Route path="/buat-aktivitas" element={<CreateJadwalOnce />} />


              <Route path="/buat-komunitas" element={<CreateCommunity />} />
              <Route path="/share-komunitas/:code/:id" element={<ShareCommunity />} />
              <Route path="/edit-komunitas/:code" element={<EditCommunity />} />


              <Route path="/minat" element={<Minat />} />
              
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/update" element={<ProfileUpdate />} />
              <Route path="/profile/penarikan" element={<Penarikan />} />
              <Route path="/profile/riwayat" element={<Riwayat />} />

              <Route path="/komunitas/:id" element={<DetailCommunity />} />
              <Route path="/komunitas/:id/regular" element={<CreateJadwalRegular />} />
              <Route path="/komunitas/:id/once" element={<CreateJadwalOnce />} />

              {/* activity */}
              <Route path="/komunitas/:id/edit-aktivitas/:id_aktivitas" element={<EditActivityRegular />} />
              <Route path="/komunitas/:id/edit-aktivitas-sesi/:id_aktivitas" element={<EditJadwalOnce />} />
                {/* personal */}
              <Route path="/edit-aktivitas-sesi/:id_aktivitas" element={<EditJadwalOnce />} />
              {/* end activity */}

              {/* search */}
              {/* route redirect */}
              <Route path='/search' element={<Navigate to="/search/public" replace/>} />
              
              <Route path="/search/:type" element={<SearchPage />} />
              <Route path="/search/id/:id" element={<SearchFoundPage />} />

              {/* Explore */}
              <Route path="/explore/:id" element={<ExplorePage />} />

              {/* Aktivitas */}
              <Route path='/aktivitas' element={<Navigate to="/aktivitas/berjalan" replace/>} />
              <Route path="/aktivitas/:type" element={<AktivitasPage />} />
              {/* End Aktivitas */}
              <Route path="/join/:code" element={<JoinPage />} />

              <Route path="/sukses-bergabung-aktivitas/:id" element={<SuksesBergabungAktivitas />} />

              <Route path="/logout" element={<Logout/>} />
              
              

            </Routes>
          </Suspense>
        </QueryClientProvider>
      </BrowserRouter>
    </CacheBuster>
  );
}

export default App;
